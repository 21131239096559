export const getCitasAnexos = async (citaID: any) => {
  try {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/citaAnexo/listar?citaID=${citaID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const JsonData = await data.json();
    return JsonData.respuesta;
  } catch (error) {}
};
