import React from 'react'
import { styled } from '@material-ui/core';  
import VisibilityIcon from '@material-ui/icons/Visibility';

const CardInfo = styled('div')(({ theme }) => ({
    width:'100%',
    padding: "1em",
    border: "0px none",
    borderRadius: "0.5em",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    cursor: 'pointer',
    marginTop: '1em',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 1em',
      minWidth: '15em'
    },
  })); 

export const CardCitaMedicaInfo = ({ info,setCitaVista,setCitaActiva }: any) => { 

      const handleChangeVista = (info:any) => {
        setCitaVista("unica");
        setCitaActiva(info);
    }
   
    return (  
        <CardInfo>
            <div className="showIcon" onClick={() => handleChangeVista(info)} > <VisibilityIcon /> </div> 
            <h3 style={{margin:'0'}}>{info.especialidad}</h3>
            <hr />
            <h4 style={{margin:'0'}}>Fecha: <span>{info.fecha_atencion}</span></h4>
            <span>Doctor: {info.medico}</span>
        </CardInfo> 
 
        
    )
}
