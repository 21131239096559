import React, { useEffect } from 'react';

export const MainMenu = ({ setShow, formData }: any) => {
  const handleShowMenu = ({ target }: any) => {
    setShow({ [target.name]: true });
  };

  const { formValues, examenesAuxDetalle, medicamentosDetalle, diagnosticosDetalle } = formData;

  const {
    ant_patologicos,
    ant_quirurgicos,
    ant_alergicos,
    ant_familiares,
    ant_epidemiologicos,
    ant_peso,
    ant_talla,
    ant_presion_art,
    ant_frecuencia_card,
    ant_frecuencia_resp,
    ant_temperatura,
    ant_examenClinicoGeneral,
    ant_examenClinicoEspecifico,
    ant_otros,
    receta_tratamiento,
    tiempo_enfermo,
    inicio,
    curso,
    relato,
    recetaTexto
  } = formValues;



  /* ${ant_patologicos?.length > 0 &&
          ant_quirurgicos?.length > 0 &&
          ant_alergicos?.length > 0 &&
          ant_familiares?.length > 0 &&
          ant_epidemiologicos?.length > 0 &&
          ant_peso?.length > 0 &&
          ant_talla?.length > 0 &&
          ant_presion_art?.length > 0 &&
          ant_frecuencia_card.length > 0 &&
          ant_frecuencia_resp?.length > 0 &&
          ant_temperatura?.length > 0 &&
          ant_examenClinicoGeneral?.length > 0 &&
          ant_examenClinicoEspecifico?.length > 0 &&
          ant_otros?.length > 0 && */ 

  return (
    <div className="options animate__animated animate__fadeIn">
      <div className="titulo_seccion">Menu Principal</div>
      <hr />
      <button
        className={`main_button
          ${diagnosticosDetalle?.length > 0 && 'complete'} `}
        name="ant_main"
        onClick={handleShowMenu}
      >
        Antecedentes
      </button>

      <button
        className={`main_button ${relato?.length > 0 &&
          tiempo_enfermo?.length > 0 &&
          inicio?.length > 0 &&
          curso?.length > 0 &&
          'complete'}`}
        name="anamnesis"
        onClick={handleShowMenu}
      >
        Anamnesis
      </button>

      {/* <button
        className={`main_button ${examenesAuxDetalle?.length > 0 && 'complete'}`}
        name="examenes_aux_main"
        onClick={handleShowMenu}
      >
        Examenes auxiliares
      </button> */}

      
      {/* Receta desabilitada por el momento */}

      <button
        className={`main_button ${recetaTexto?.length > 0 && 'complete'}`}
        name="receta"
        onClick={handleShowMenu}
        
      >
        Receta
      </button>

      <button
        className={`main_button ${receta_tratamiento?.length > 0 && 'complete'}`}
        name="tratamiento"
        onClick={handleShowMenu}
      >
        Tratamiento
      </button>

      <button className={`main_button`} name="historial_citas_main" onClick={handleShowMenu}>
        Historial de citas
      </button>
       

      {/* Anexos desabilitado por el momento */}

      {/* <button className={`main_button`} name="anexos" onClick={handleShowMenu}>
        Anexos
      </button> */}
    </div>
  );
};
