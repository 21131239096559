
export const insertarRegistroCita = async (tokenID: any, usuarioID: any) => {
    try {
        let json = {tokenID, usuarioID};
        const data = await fetch(`${process.env.REACT_APP_API_URL}/token_cita/registro/insertar`, {
            method:'POST',
            body: JSON.stringify(json), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json();
        return JsonData.respuesta;
    } catch (error) {
        return error;
    }
}
