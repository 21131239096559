import React, { useState, useEffect } from 'react'
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { setInterval } from 'timers';
import moment from 'moment'

export const TiempoRestante = ({tokenData , setDataUser}: any) => {

    const roomState = useRoomState();
    const { room } = useVideoContext();

    const { HoraFin } = tokenData[0];   
    
    const [time, setTime] = useState({ 
        hours: 0,
        minutes: 0,
        seconds: 0
    })
    
    const {hours , minutes , seconds}  = time;

    const addZero = (value:any) => {
        if(value<10){
            return '0'+value;
        }else {
            return value;
        }
    }
    

    useEffect(() => {  
        const intervalo = setInterval(()=> {
                const now = moment();
                const startTime = moment(now,'HH:mm:ss');
                const endTime = moment(HoraFin,'HH:mm:ss'); 
    
                let  duration = moment.duration(endTime.diff(startTime));
    
                let hours =  duration.hours(); 
                let minutes =  duration.minutes();
                let seconds =  duration.seconds();  
    
                setTime({hours,minutes,seconds}); 
                 
        },1000)   
        return () => {
            clearInterval(intervalo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    useEffect(() => {
       if(hours===0 && minutes === 0 && seconds === 0){
            handleCompleteTimer(); 
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [hours,minutes,seconds])
      
    const handleCompleteTimer = () => {
        if(roomState === 'connected'){
          room.disconnect(); 
          setDataUser((data: any) => ({...data,temporizadorPrendido:false}));
        }
      }

       

    return (
        <span style={{color:'#f58837'}}>{addZero(hours)}:{addZero(minutes)}:{addZero(seconds)}</span>
    )
};
