import React from 'react'
import { makeStyles, createStyles, Theme, Tooltip, Fab } from '@material-ui/core'; 
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export const TogglePacient = ({setShowInfo}:any) => {
    const classes = useStyles();

    const params = queryString.parse(useLocation().search); 
    const {type = '' } = params;

    const toggleViewFormPacient = () => {
        if (type==='1') setShowInfo((showInfo: Boolean) => !showInfo);
    }
    
    
    return (
        <Tooltip
          title={'Pacient'}
          placement="top"
          PopperProps={{ disablePortal: true }}
          style={{display: `${type !== "1" && 'none'}`}}
        >
          <Fab className={classes.fab} onClick={toggleViewFormPacient} >
            <InfoOutlinedIcon />  
          </Fab>
        </Tooltip>
      );
}
