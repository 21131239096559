import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { actualizarRegistroCita } from '../../../utils/myUtils/actualizarRegistroCita'; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton({dataUser}:any) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const { CitaRegistroID } = dataUser;  

  useEffect(() => {  
    window.addEventListener("beforeunload", async function (e) { 
      await actualizarRegistroCita(CitaRegistroID,"");
      (e || window.event).returnValue = null;
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const handleEndCall = async () => {
    room.disconnect();  
    await actualizarRegistroCita(CitaRegistroID,"");
  }

  return (
    <Tooltip title={'End Call'}  onClick={handleEndCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} color="primary">
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
