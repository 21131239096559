  
export const actualizarEstadoCita = async (citaid: any, estadoid: any) => {
    try {
        let json = {citaid,estadoid};
        const data = await fetch(`${process.env.REACT_APP_API_URL}/estado/cita/modificar`, {
            method:'POST',
            body: JSON.stringify(json), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.respuesta;
    } catch (error) {
        return error;
    }
}
