export const getDiagnostico = async (diagnostico : any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/diagnostico/listar?diagnostico=${diagnostico}`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.rows;
    } catch (error) {
    }
}

export const getDiagnosticoTipo = async () => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/diagnostico_tipo/listar`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.respuesta;
    } catch (error) {
    }
}


export const getMedicamento = async (medicamento : any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/medicamento/listar?medicamento=${medicamento}`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.rows;
    } catch (error) {
    }
}

export const getRecetaDosis = async () => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/receta/dosis/listar`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.respuesta;
    } catch (error) {
    }
}

export const getRecetaAdministracion = async () => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/receta/administracion/listar`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.respuesta;
    } catch (error) {
    }
}

export const insertarAtencionMedica = async (objetoAtencionMedica:any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/atencionMedica/post`, {
            method:'POST',
            body: JSON.stringify({objetoAtencionMedica}), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
        });
        let JsonData = await data.json();  
        return JsonData;
    } catch (error) {
    }
}

export const insertarAtencionMedicaLocal = async (citaID:any, receta:any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/atencionMedica/local/post`, {
            method:'POST',
            body: JSON.stringify({citaID,receta}), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
        });
        let JsonData = await data.json();  
        return JsonData;
    } catch (error) {
    }
}


export const guardarAudio = async (formData:any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/audio/guardar`, {
            method:'POST',
            body: formData, 
        });
        const JsonData = await data.json(); 
        return JsonData;
    } catch (error) {
    }
}
 

export const getEspecialidadesAuxiliares = async () => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/especialidad/examenesAux/listarApoyo`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.rows;
    } catch (error) {
    }
}

export const getListarExamenes = async (idEspecialidadSC:any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/producto/listarExamenes?id_especialidad=${idEspecialidadSC}`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.rows;
    } catch (error) {
    }
}

export const getHistoriaClinica = async (numeroDocumento:any) => {
    try {
        const data = await fetch(`${process.env.REACT_APP_API_URL_SC}/atencionMedica/historiaClinica/listar?numero_documento=${numeroDocumento}`, {
            method:'GET', 
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.rows;
    } catch (error) {
    }
}