import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import VideocamOff from '@material-ui/icons/VideocamOff';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PersonIcon from '@material-ui/icons/Person'; 

const useStyles = makeStyles((theme: Theme) => createStyles({  
  container: {
    display:'flex',
    width:'100%',
    justifyContent:'center',
    maxHeight: '100%',
    objectFit: 'contain',
    overflow:'hidden',
    minHeight: '55vh',
  },
  isVideoSwitchedOff: {
    '& video': {
      filter: 'blur(4px) grayscale(1) brightness(0.5)',
    },
  },
  identity: {
    background: '#ffffffcf',
    padding: '0.5em',
    margin: '1em',
    fontSize: '1.2em',
    display: 'inline-flex',
    boxShadow: '0px 0px 12.646px rgba(0,0,0,0.15)',
    borderRadius: '0.5em', 
    justifyContent: 'center',
    alignItems: 'center',
    color:'#28a745',
    '& svg': {
      marginLeft: '0.3em',
    }
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 1, 
    padding: '0.4em',   
    display: 'flex',  
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  paper: {
    border: "0px none",
    padding: theme.spacing(1),
    background: "#ffffffcf",
    boxShadow: "0px 0px 12.646px rgba(0,0,0,0.15)",
    borderRadius: "0.5em",
    marginTop: "0.5em"
  },
})) ;

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  setShowInfo: any;
}

export default function MainParticipantInfo({ participant, children, setShowInfo }: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);


  const params = queryString.parse(useLocation().search); 
  const { type = '' } = params;


  const handleChangeShowInfo = (e: any) => {
    if (type==='1') setShowInfo((showInfo: Boolean) => !showInfo);
  }
 
  return (
    <div
      data-cy-main-participant
      className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })} 
    >
      <div className={classes.infoContainer} >
        <h4 className={classes.identity} style={{cursor: type === "1" ? 'pointer' : ''}}  onClick={handleChangeShowInfo} >
          {type === "1" ? <PersonIcon style={{marginRight:'5px'}} /> : <LocalHospitalIcon style={{marginRight:'5px'}} /> }
          {participant.identity}
          {!isVideoEnabled && <VideocamOff />}
        </h4>
      {!isVideoEnabled && <h3>{type === "1"?  'El paciente tiene desactivado el video' : 'El médico tiene desactivado el video'}</h3>}
      </div>
      
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}


 
 