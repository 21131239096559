import React  from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import {  makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant'; 
import { PacientStrip } from '../PacientStrip/PacientStrip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerDoctor: {
      display:'flex',
      flexDirection:'row-reverse',
      width:'100%',
      justifyContent:'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection:'column-reverse'
      }
    }, 
  })
);

export default function Room({showInfo,setShowInfo, tokenData}: any) {

  const classes = useStyles();
 
  return (
    <div className={classes.containerDoctor}> 
      <PacientStrip showInfo={showInfo} tokenData={tokenData}  />
      <ParticipantStrip />
      <MainParticipant setShowInfo={setShowInfo} showInfo={showInfo} />
    </div>
  );
}
