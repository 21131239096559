import React, { useContext } from 'react'
import { TextField } from '@material-ui/core';
import { FormContext } from '../../../hooks/useForm/FormContext';

export const Tratamiento = ({setShow} : any) => {
    
    const { formValues ,handleInputChange } = useContext(FormContext);
    const { receta_tratamiento  } = formValues;

    const handleVolver = () => {
        setShow({mainmenu:true});
    }

    return (
        <div className="options animate__animated animate__fadeIn">
            <div className="titulo_seccion">
                Tratamiento
            </div>
            <hr />
            <button className="main_button back" onClick={handleVolver}>
                Volver
            </button>
             
            <div className="content"> 

                <div className="divInput"> 
                    <TextField 
                        id="outlined-basic" 
                        label="Tratamiento" 
                        variant="outlined"  
                        type="text" 
                        multiline 
                        fullWidth 
                        rows="5" 
                        color="secondary"
                        onChange={handleInputChange}
                        name="receta_tratamiento"
                        value={receta_tratamiento}
                    />
                    </div> 

            </div>

        </div>
    )
}
