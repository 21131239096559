import React, { useContext } from 'react'
import { TextField } from '@material-ui/core';
import { FormContext } from '../../../hooks/useForm/FormContext';

export const Anamnesis = ({setShow}:any) => {

    const { formValues ,handleInputChange } = useContext(FormContext);
    const {  relato, tiempo_enfermo, inicio, curso  } = formValues;
 
    const handleVolver = () => {
        setShow({mainmenu:true});
    }
   
    return (
      
            <div className="options animate__animated animate__fadeIn">
                <div className="titulo_seccion">
                    Anamnesis
                </div>
                <hr />
                <button className="main_button back" onClick={handleVolver}>
                    Volver
                </button>  
                <div className="content"> 
                    <div className="divInput"> 
                        <TextField  
                            label="Tiempo enfermo" 
                            aria-describedby="Tiempo enfermo"  
                            color="secondary"
                            onChange={handleInputChange}
                            name="tiempo_enfermo"
                            fullWidth
                            value={tiempo_enfermo}
                            autoComplete="off"
                        />
                    </div> 
                    <div className="divInput"> 
                        <TextField  
                            label="Inicio" 
                            aria-describedby="Inicio"  
                            color="secondary"
                            onChange={handleInputChange}
                            name="inicio"
                            fullWidth
                            value={inicio}
                            autoComplete="off"
                        />
                    </div>
                    <div className="divInput"> 
                        <TextField  
                            label="Curso" 
                            aria-describedby="Curso"  
                            color="secondary"
                            onChange={handleInputChange}
                            name="curso"
                            fullWidth
                            value={curso}
                            autoComplete="off"
                        />
                    </div>
                    <div className="divInput"> 
                        <TextField 
                            id="outlined-basic" 
                            label="Relato" 
                            variant="outlined"  
                            type="text" 
                            multiline 
                            fullWidth 
                            rows="5" 
                            color="secondary"
                            onChange={handleInputChange}
                            name="relato"
                            value={relato}
                        />
                    </div> 
                </div>
            </div>
            
      
        
    )
}
