import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
 
import { useLocation } from 'react-router-dom';
import { getDataToken } from './utils/myUtils/getDataToken';
import queryString from 'query-string' 
import { CircularProgress } from '@material-ui/core';
import { insertarRegistroCita } from './utils/myUtils/insertarRegistroCita';  
import { PacientStrip } from './components/PacientStrip/PacientStrip';
import theme from './theme';

const Container = styled('div')({
  // display: 'grid',
  // gridTemplateRows: 'auto 1fr',
  display:'flex',
  flexDirection:'column',
});

const Main = styled('main')({
  // overflow: 'hidden',
  overflow: 'hidden',
  display:'flex',
  flexDirection:'row', 
  [theme.breakpoints.down('xs')]: {
    flexDirection:'column'
  }
});

const MessageScreen = styled('div')({
  height:'100vh',
  width:'100vw',
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  justifyItems:'center', 
  textAlign:'center'
});

const DivVideo = styled('div')({
  width: '100%',
  minWidth: '65%',
  maxHeight: '100%',
  objectFit: 'contain',
  display: 'flex',
  [theme.breakpoints.down('xs')]: {
    minHeight: '55vh!important' 
  }
});

export default function App() {
  const roomState = useRoomState();
  const height = useHeight();
  const [tokenData, setTokenData] = useState([]); 
  const params = queryString.parse(useLocation().search); 
  const { token_cita, type = '' } = params;
  const [response, setResponse] = useState({loading:true,done:false});
  const [dataUser, setDataUser] = useState({
      nombreLocal: '',
      especialidad: '',
      startVideo: false,
      nombreSegundoParticipante: '', 
      CitaRegistroID: '',
      socketID:'',
      temporizadorPrendido:true
    });

  const [showInfo, setShowInfo] = useState(false);  
   
  const insertarRegistro = async (tokenID : any, medicoUsuarioID: any) => {
    const data = await insertarRegistroCita(tokenID,medicoUsuarioID);
    const { VALOR } = data[0];
    setDataUser(data => ( {...data, CitaRegistroID: VALOR }));  
  }
 
  useEffect(() => { 
    if (token_cita) {
      getDataToken(token_cita).then(data => {
        if (data.length === 0) return setResponse({loading:false,done:false});
        setTokenData(data);  
      })
      .catch(e => {
        setResponse({loading:false,done:false});
      })
    } else {
      setResponse({loading:false,done:false})
    }
  }, [token_cita]); 

  useEffect(() => {
    if(response.done) return;
    if(tokenData.length === 0 )return;
      setResponse({loading:false,done:true});
      const {NombreMedico, NombrePaciente, EspecialidadNombre } = tokenData[0]; 
      if (type === '1') { 
        setDataUser((data) => ({...data,nombreLocal:NombreMedico,nombreSegundoParticipante:NombrePaciente,especialidad:EspecialidadNombre})); 
      } else { 
        setDataUser((data) => ({...data,nombreLocal:NombrePaciente,nombreSegundoParticipante:NombreMedico,especialidad:EspecialidadNombre})); 
      }
  }, [response,tokenData,type])
 
  return (
    <>
      {
        response.loading ? (
          <MessageScreen>
            <div>
              <CircularProgress /> 
              <h2 style={{margin:'0',color:'#808080'}}>Cargando</h2>
            </div>
          </MessageScreen>
        ) : ( 
          response.done ? (
            dataUser.temporizadorPrendido || type === "1" ? (
              <Container style={{ height }}>
                <MenuBar showInfo={showInfo} tokenData={tokenData} params={params} dataUser={dataUser}  setDataUser={setDataUser} insertarRegistro={insertarRegistro} />
                <Main>
                  <DivVideo>
                    {roomState === 'disconnected' ? <LocalVideoPreview showInfo={showInfo} /> : <Room showInfo={showInfo} setShowInfo={setShowInfo} tokenData={tokenData} />}
                  </DivVideo>
                  <Controls  setShowInfo={setShowInfo} dataUser={dataUser} />
                  {roomState === 'disconnected' && <PacientStrip showInfo={showInfo} tokenData={tokenData}  />} 
                </Main>
                <ReconnectingNotification />
              </Container>
            )
            :
              <MessageScreen>
                <div>
                  <img src={"/img/logo.svg"} style={{width:'15em'}} alt="logo" ></img>
                  <h2 style={{margin:'0',color:'#808080'}}>La consulta terminó. Gracias por su preferencia.</h2> 
                </div>
              </MessageScreen>

          )
          :
          <MessageScreen>
            <div>
              <img src={"/img/logo.svg"} style={{width:'15em'}} alt="logo" ></img>
              <h2 style={{margin:'0',color:'#808080'}}>No tiene una cita en este momento</h2> 
            </div>
              
          </MessageScreen>  
        )
      }  
    </>
  );
}
