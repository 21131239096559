import React, { useState, useContext, useEffect, useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import { MainMenu } from './MainMenu/MainMenu';
import { Antecedentes } from './Antecedentes/Antecedentes';
import { Personales } from './Antecedentes/Personales/Personales';

import './pacientstrip.css';
import { Familiares } from './Antecedentes/Familiares/Familiares';
import { Epidemiologicos } from './Antecedentes/Epidemiologicos/Epidemiologicos';
import { Otros } from './Antecedentes/Otros/Otros';
import { ExamenClinico } from './Antecedentes/ExamenClinico/ExamenClinico';
import { ExamenesAuxiliares } from './ExamenesAuxiliares/ExamenesAuxiliares';
import { Receta } from './Receta/Receta';
import { HistorialCitas } from './HistorialCitas/HistorialCitas';
import { Anamnesis } from './Anamnesis/Anamnesis';
import { FormContext } from '../../hooks/useForm/FormContext';
import { ImpresionDiagnostica } from './Antecedentes/ImpresionDiagnostica/ImpresionDiagnostica';
import { Tratamiento } from './Tratamiento/Tratamiento';
import Anexos from './Anexos/Anexos';

const Container = styled('aside')(({ theme }) => ({
  padding: '0.5em',
  overflowY: 'auto',
  width: '35%',
  overflowX: 'hidden',
  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
    width: '100%',
    height: '45vh',
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

interface PacientStripProps {
  showInfo: any;
  tokenData: any;
}

export const PacientStrip = ({ showInfo, tokenData }: PacientStripProps) => {
  let { refreshButton } = useContext(FormContext);

  const formData = useContext(FormContext);

  const container = useRef<any>();

  const [show, setShow] = useState({
    mainmenu: true,
    ant_main: false,
    ant_personales: false,
    ant_familiares: false,
    ant_epidemiologicos: false,
    ant_otros: false,
    ant_examenclinico: false,
    ant_impresion_diagnostica: false,
    examenes_aux_main: false,
    receta: false,
    tratamiento: false,
    historial_citas_main: false,
    anamnesis: false,
    anexos: false,
  });

  useEffect(() => {
    if (container) {
      container.current.scrollTo(0, 0);
    }
  }, [show, container]);

  const {
    mainmenu,
    ant_main,
    ant_personales,
    ant_familiares,
    ant_epidemiologicos,
    ant_otros,
    ant_examenclinico,
    ant_impresion_diagnostica,
    examenes_aux_main,
    receta,
    tratamiento,
    historial_citas_main,
    anamnesis,
    anexos,
  } = show;

  useEffect(() => {
    setShow({
      mainmenu: true,
      ant_main: false,
      ant_personales: false,
      ant_familiares: false,
      ant_epidemiologicos: false,
      ant_otros: false,
      ant_examenclinico: false,
      ant_impresion_diagnostica: false,
      examenes_aux_main: false,
      receta: false,
      tratamiento: false,
      historial_citas_main: false,
      anamnesis: false,
      anexos: false,
    });
  }, [refreshButton]);

  return (
    <Container
      ref={container}
      style={{ display: showInfo ? '' : 'none' }}
      className="animate__animated animate__fadeIn"
    >
      <ScrollContainer>
        {mainmenu && <MainMenu setShow={setShow} formData={formData} />}

        {ant_main && <Antecedentes setShow={setShow} formData={formData} />}

        {ant_personales && <Personales setShow={setShow} />}

        {ant_familiares && <Familiares setShow={setShow} />}

        {ant_epidemiologicos && <Epidemiologicos setShow={setShow} />}

        {ant_otros && <Otros setShow={setShow} />}

        {ant_examenclinico && <ExamenClinico setShow={setShow} />}

        {ant_impresion_diagnostica && <ImpresionDiagnostica setShow={setShow} container={container} />}

        {examenes_aux_main && <ExamenesAuxiliares setShow={setShow} />}

        {receta && <Receta setShow={setShow} />}

        {tratamiento && <Tratamiento setShow={setShow} />}

        {historial_citas_main && <HistorialCitas setShow={setShow} tokenData={tokenData} container={container} />}

        {anamnesis && <Anamnesis setShow={setShow} />}

        {anexos && <Anexos setShow={setShow} tokenData={tokenData} />}
      </ScrollContainer>
    </Container>
  );
};
