import React, { useEffect, useContext, useState } from 'react'
import { TextField, MenuItem, BottomNavigation, BottomNavigationAction, makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { FormContext } from '../../../hooks/useForm/FormContext';
import { getEspecialidadesAuxiliares, getListarExamenes } from '../../../utils/myUtils/ExamenClinico';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CreateIcon from '@material-ui/icons/Create';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { CardExamenAuxInfo } from '../CardExamenAuxInfo/CardExamenAuxInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            background: 'none',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
    })
); 
  
export const ExamenesAuxiliares = ({setShow } : any) => {

    const { examenesAuxDetalle, setExamenesAuxDetalle } = useContext(FormContext); 

    const [examenesAux, setExamenesAux] = useState<any[]>([]);
    const [especialidadesAux, setEspecialidadesAux] = useState<any[]>([]);

    const [values, setValues] = useState<any>({}); 
    const { exa_aux_especialidad, exa_aux_examen} = values;

    const classes = useStyles();
    const [value, setValue] = React.useState('registro');

    
    useEffect(() => {
        getEspecialidadesAuxiliares().then(data => {
            setEspecialidadesAux(data);
        }).catch(err => {
        })
    }, [])

    useEffect(() => {
        if(exa_aux_especialidad?.id?.length > 0){
            getListarExamenes(exa_aux_especialidad.id).then(data => {
                setExamenesAux(data);
            }).catch(err => {
            })
        }
    }, [exa_aux_especialidad])


    const handleAgregarExamen = () => {
        if(exa_aux_especialidad?.id?.length > 0 && exa_aux_examen?.id_producto?.length > 0){
            setExamenesAuxDetalle((detalle:any) => [...detalle,{
                id: uuidv4(),
                id_producto:exa_aux_examen.id_producto, 
                especialidad_nombre: exa_aux_especialidad.nombre,
                especialidad_precio: exa_aux_especialidad.precio,
                examen_nombre: exa_aux_examen.descripcion,
                examen_precio: exa_aux_examen.precio
            }])
            setValues((values:any) => ({...values,
            exa_aux_especialidad:''
            ,exa_aux_examen:''})); 
            setValue('examenes');
            Swal.fire('Mensaje','Se agrego correctamente el exámen','success'); 

        }
    }

    
    const handleSelectChange = async ({ target }:any) => {

        setValues({
            ...values,
            [ target.name ]: target.value
        })
        
    } 
    

    const handleVolver = () => {
        setShow({mainmenu:true});
    }

    return ( 
        
        <div className="options animate__animated animate__fadeIn">
            <div className="titulo_seccion">
                Exámenes Auxiliares
            </div>
            <hr />
            <button className="main_button back" onClick={handleVolver}>
                Volver
            </button> 
            <div className="content">

                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);  
                    }}
                    showLabels
                    className={classes.root}
                    >
                    <BottomNavigationAction label="Ver exámenes" value="examenes" icon={<ReceiptIcon />} />
                    <BottomNavigationAction label="Registrar" value="registro" icon={<CreateIcon />} /> 
                </BottomNavigation>

                {value === "registro" && 
                    <div className="content animate__animated animate__fadeIn"> 
                        <div className="divInputSelect">  
                            <TextField 
                                select
                                label="Especialidad" 
                                helperText="Selecciona una especialidad auxiliar"
                                color="secondary"
                                fullWidth
                                onChange={handleSelectChange}
                                name="exa_aux_especialidad"
                                value={exa_aux_especialidad}  
                                >   
                                <MenuItem key={0} value="">
                                    Ninguno
                                </MenuItem>
                                {  
                                    especialidadesAux?.length > 0 &&
                                    especialidadesAux.map((especialidad) => (
                                            <MenuItem key={especialidad.id} value={especialidad}   >
                                                {especialidad.nombre} - S./{especialidad.precio}
                                            </MenuItem> 
                                        ))  
                                }
                                
                            </TextField>
                        </div> 

                        <div className="divInputSelect">  
                            <TextField 
                                select
                                label="Exámenes" 
                                helperText="Selecciona un examén"
                                color="secondary"
                                fullWidth
                                onChange={handleSelectChange}
                                name="exa_aux_examen"
                                value={exa_aux_examen}  
                                >   
                                <MenuItem key={0} value="">
                                    Ninguno
                                </MenuItem>
                                {  
                                    examenesAux?.length > 0 &&
                                    examenesAux.map((examen) => (
                                            <MenuItem key={examen.id_producto} value={examen}  >
                                                {examen.descripcion} - S./{examen.precio}
                                            </MenuItem> 
                                        ))  
                                }
                                
                            </TextField>
                        </div> 

                        <div className="divInput">
 
                            <Button color="secondary" variant="contained" fullWidth onClick={handleAgregarExamen}>Agregar</Button>
                    
                        </div>
                    </div>
                }

                {value === "examenes" && 
                    examenesAuxDetalle?.length > 0 &&
                    examenesAuxDetalle.map((examen_aux:any) => (
                        <CardExamenAuxInfo key={examen_aux.id} examen_aux={examen_aux} />
                    ))
                }

                
            </div>
        </div>
            
      
    )
}
