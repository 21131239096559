import React, { useEffect, useContext, useState } from 'react'
import { InputAdornment, TextField } from '@material-ui/core';
import { FormContext } from '../../../../hooks/useForm/FormContext'; 
import { getIMC } from '../../../../utils/myUtils/getIMC';


export const ExamenClinico = ({setShow}: any ) => {

    const { formValues ,handleInputChange } = useContext(FormContext);
    const { ant_peso, ant_talla, ant_examenClinicoGeneral, ant_examenClinicoEspecifico
        , ant_presion_art, ant_frecuencia_card, ant_frecuencia_resp,ant_temperatura} = formValues;
   
    
    const [imcValue, setImcValue] = useState(0);
     

    useEffect(() => { 
        if(ant_peso > 0 && ant_talla > 0){
            let value = (ant_peso / (Math.pow(ant_talla,2))).toFixed(2); 

            setImcValue(parseFloat(value));

            handleInputChange({
                target: {
                    name:'ant_imc',
                    value:value
            }});

        }
         // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [ant_peso,ant_talla])

    const handleVolver = () => {
        setShow({ant_main:true});
    }

    return (
         
        <div  className="options animate__animated animate__fadeIn">
            <div className="titulo_seccion">
                Ant. Examen Clínico
            </div>
            <hr />
            <button className="main_button back" onClick={handleVolver}>
                Volver
            </button>
            <div className="content">
                <div className="divInput"> 
                    <TextField  
                        type="number"
                        label="Peso" 
                        aria-describedby="Peso" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_peso"
                        value={ant_peso}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput">
                    <TextField  
                        type="number"
                        label="Talla" 
                        aria-describedby="Talla" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_talla"
                        value={ant_talla}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput">
                    <TextField  
                        label="IMC" 
                        aria-describedby="IMC" 
                        disabled 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">IMC</InputAdornment>, 
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_imc"
                        value={imcValue}
                        style={{color:"black!important"}}

                    />
                    <div className="imc_descripcion">
                        {
                            ( ant_peso > 0 && ant_talla > 0 ) && getIMC(imcValue)
                        }
                    </div> 
                </div> 
                <div className="divInput">
                    <TextField   
                        type="number"
                        label="Presion Arterial" 
                        aria-describedby="Presion Arterial" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_presion_art"
                        value={ant_presion_art}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput">
                    <TextField  
                        type="number"
                        label="Frecuencia Cardiaca" 
                        aria-describedby="Frecuencia cardiaca" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">x Min</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_frecuencia_card"
                        value={ant_frecuencia_card}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput">
                    <TextField  
                        type="number"
                        label="Frecuencia Respiratoria" 
                        aria-describedby="Frecuencia Respiratoria" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">x Min</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_frecuencia_resp"
                        value={ant_frecuencia_resp}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput">
                    <TextField  
                        type="number"
                        label="Temperatura" 
                        aria-describedby="Temperatura" 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                        }}
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_temperatura"
                        value={ant_temperatura}
                        autoComplete="off"
                    />
                </div> 
                <div className="divInput"> 
                    <TextField 
                        id="outlined-basic" 
                        label="Exámen clínico general" 
                        variant="outlined"  
                        type="text" 
                        multiline 
                        fullWidth 
                        rows="5" 
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_examenClinicoGeneral"
                        value={ant_examenClinicoGeneral}
                    />
                </div> 
                <div className="divInput"> 
                    <TextField 
                        id="outlined-basic" 
                        label="Exámen clínico especifico" 
                        variant="outlined"  
                        type="text" 
                        multiline 
                        fullWidth 
                        rows="5" 
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_examenClinicoEspecifico"
                        value={ant_examenClinicoEspecifico}
                    />
                </div> 
            </div>
        </div> 
        
         
        
    )
}
