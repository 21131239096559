import React, { useContext } from 'react'
import { styled } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Swal from 'sweetalert2';
import { FormContext } from '../../../hooks/useForm/FormContext';

const CardInfo = styled('div')(({ theme }) => ({
    width:'100%',
    padding: "1em",
    border: "0px none",
    borderRadius: "0.5em",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px', 
    marginTop: '1em',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 1em',
      minWidth: '15em'
    },
  })); 
  
export const CardDiagnosticoInfo = ({ diagnostico }: any) => {

  const {setDiagnosticosDetalle, diagnosticosDetalle} = useContext(FormContext);

    const handleDelete = (diagnostico:any) => { 
      Swal.fire({
        title: 'Eliminar',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar',
        cancelButtonText:'Cancelar'
      }).then( async (result) => {
        if (result.isConfirmed) {
          let data =  diagnosticosDetalle.filter((e:any) =>  e.id !== diagnostico.id );
          setDiagnosticosDetalle(data);
        }
      });
    }
  
    
    return (
        <CardInfo className="animate__animated animate__fadeIn" >
            <div className="dropIcon" onClick={() => handleDelete(diagnostico)} > <HighlightOffIcon /> </div> 
            <h3 style={{margin:'0'}}>{diagnostico.descripcion}</h3>
            <hr /> 
            <div><b>Tipo Diagnostico: </b>{diagnostico.tipo_diagnostico}</div> 
        </CardInfo>  
    )
}
