import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  TextField,
  MenuItem,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { FormContext } from '../../../hooks/useForm/FormContext';
import { getMedicamento, getRecetaDosis, getRecetaAdministracion } from '../../../utils/myUtils/ExamenClinico';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CreateIcon from '@material-ui/icons/Create';
import { CardMedicamentoInfo } from '../CardMedicamentoInfo/CardMedicamentoInfo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: 'none',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
);

export const Receta = ({ setShow }: any) => {
  const { setMedicamentosDetalle, medicamentosDetalle, formValues ,handleInputChange } = useContext(FormContext);

  const { recetaTexto } = formValues;

  const [values, setValues] = useState<any>({});
  const { medicamento_cantidad, dosis, administracion, medicamento_frecuencia, medicamento_duracion } = values;

  const [medicamentos, setMedicamentos] = useState<any[]>([]);
  const [recetasDosis, setRecetasDosis] = useState<any[]>([]);
  const [recetasAdministracion, setRecetasAdministracion] = useState<any[]>([]);

  const [medicamentoObjeto, setMedicamentoObjeto] = React.useState<any>({});
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState('registro');

  const refDosis = useRef<any>(null);

  useEffect(() => {
    getRecetaDosis()
      .then(data => {
        setRecetasDosis(data);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    getRecetaAdministracion()
      .then(data => {
        setRecetasAdministracion(data);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    if (inputValue?.length === 2) {
      getMedicamento(inputValue)
        .then(data => {
          setMedicamentos(data);
        })
        .catch(err => {});
    }
  }, [inputValue]); 

  const handleVolver = () => {
    setShow({ mainmenu: true });
  };

  const handleSelectChange = async ({ target }: any) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const handleAgregarMedicamento = () => {
    if (
      medicamentoObjeto?.id_medicamento !== undefined &&
      medicamento_cantidad?.length > 0 &&
      dosis?.RecetaDosisID !== undefined &&
      administracion?.RecetaAdministracionID !== undefined &&
      medicamento_frecuencia?.length > 0 &&
      medicamento_duracion?.length > 0
    ) {
      setMedicamentosDetalle((detalle: any) => [
        ...detalle,
        {
          id: uuidv4(),
          medicamento_id: medicamentoObjeto.id_medicamento,
          descripcion: medicamentoObjeto.descripcion,
          presentacion: medicamentoObjeto.presentacion,
          cantidad: medicamento_cantidad,
          id_dosis: dosis.TipoID,
          dosis_descripcion: dosis.Descripcion,
          id_via_administracion: administracion.TipoID,
          administracion_descripcion: administracion.Descripcion,
          medicamento_frecuencia: medicamento_frecuencia,
          medicamento_duracion: medicamento_duracion,
        },
      ]);
      setMedicamentoObjeto({});
      setInputValue('');
      setValues((values: any) => ({
        ...values,
        medicamento_cantidad: '',
        medicamento_frecuencia: '',
        medicamento_duracion: '',
        dosis: '',
        administracion: '',
      }));
      setValue('recetas');
      Swal.fire('Mensaje', 'Se agrego correctamente la receta', 'success');
    }
  };

  const classes = useStyles();

  return (
    <div className="options animate__animated animate__fadeIn">
      <div className="titulo_seccion">Receta</div>
      <hr />
      <button className="main_button back" onClick={handleVolver}>
        Volver
      </button>

      <div className="content">


      <div className="divInput"> 
        <TextField 
            id="outlined-basic" 
            label="Receta" 
            variant="outlined"  
            type="text" 
            multiline 
            fullWidth 
            rows="5" 
            color="secondary"
            onChange={handleInputChange}
            name="recetaTexto"
            value={recetaTexto}
        />
      </div>

        {/* <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction label="Ver Recetas" value="recetas" icon={<BusinessCenterIcon />} />
          <BottomNavigationAction label="Registrar" value="registro" icon={<CreateIcon />} />
        </BottomNavigation> */}

        {/* {value === 'registro' && (
          <div className="content animate__animated animate__fadeIn">
            <div className="divInputAutocomplete">
              <Autocomplete
                defaultValue={[]}
                value={medicamentoObjeto}
                onChange={(event: any, newValue: {}) => {
                  setMedicamentoObjeto(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={medicamentos || []}
                getOptionLabel={option => option.descripcion + option.presentacion}
                fullWidth
                color="secondary"
                renderInput={params => (
                  <TextField {...params} label="Medicamento" variant="outlined" color="secondary" />
                )}
              />
            </div>

            <div className="divInputSelect">
              <TextField
                select
                label="Dosis"
                helperText="Selecciona una dosis"
                color="secondary"
                fullWidth
                onChange={handleSelectChange}
                name="dosis"
                value={dosis}
                ref={refDosis}
              >
                {recetasDosis?.length > 0 &&
                  recetasDosis.map(dosis => (
                    <MenuItem key={dosis.RecetaDosisID} value={dosis}>
                      {dosis.Descripcion}
                    </MenuItem>
                  ))}
              </TextField>
            </div>

            <div className="divInputSelect">
              <TextField
                select
                label="Administracion"
                helperText="Selecciona una administracion"
                color="secondary"
                fullWidth
                onChange={handleSelectChange}
                name="administracion"
                value={administracion}
              >
                {recetasAdministracion?.length > 0 &&
                  recetasAdministracion.map(administracion => (
                    <MenuItem key={administracion.RecetaAdministracionID} value={administracion}>
                      {administracion.Descripcion}
                    </MenuItem>
                  ))}
              </TextField>
            </div>

            <div className="divInput">
              <TextField
                label="Cantidad"
                aria-describedby="Cantidad"
                color="secondary"
                onChange={handleSelectChange}
                name="medicamento_cantidad"
                fullWidth
                value={medicamento_cantidad}
                autoComplete="off"
                type="number"
              />
            </div>

            <div className="divInput">
              <TextField
                label="Frecuencia"
                aria-describedby="Frecuencia"
                color="secondary"
                onChange={handleSelectChange}
                name="medicamento_frecuencia"
                fullWidth
                value={medicamento_frecuencia}
                autoComplete="off"
              />
            </div>

            <div className="divInput">
              <TextField
                label="Duracion"
                aria-describedby="Duracion"
                color="secondary"
                onChange={handleSelectChange}
                name="medicamento_duracion"
                fullWidth
                value={medicamento_duracion}
                autoComplete="off"
              />
            </div>

            <div className="divInput">
              <Button color="secondary" variant="contained" fullWidth onClick={handleAgregarMedicamento}>
                Agregar
              </Button>
            </div>
          </div>
        )}

        {value === 'recetas' &&
          medicamentosDetalle?.length > 0 &&
          medicamentosDetalle.map((medicamento: any) => (
            <CardMedicamentoInfo key={medicamento.id} medicamento={medicamento} />
          ))} */}
      </div>
    </div>
  );
};
