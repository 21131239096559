import React, { useContext } from 'react'
import { TextField } from '@material-ui/core';
import { FormContext } from '../../../../hooks/useForm/FormContext';

export const Familiares = ({setShow}: any ) => {
 
    const { formValues ,handleInputChange } = useContext(FormContext);
    const { ant_familiares } = formValues;
    
    const handleVolver = () => {
        setShow({ant_main:true});
    }
    
    return (  

        <div className="options animate__animated animate__fadeIn">
            <div className="titulo_seccion">
                Ant. Familiares
            </div>
            <hr />
            <button className="main_button back" onClick={handleVolver}>
                Volver
            </button>
            <div className="content">
                <div className="divInput"> 
                    <TextField 
                        id="outlined-basic" 
                        label="Familiares" 
                        variant="outlined"  
                        type="text" 
                        multiline 
                        fullWidth 
                        rows="5" 
                        color="secondary"
                        onChange={handleInputChange}
                        name="ant_familiares"
                        value={ant_familiares}
                    />
                </div> 
            </div> 
        </div>
            
    )
}
