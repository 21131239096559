import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { getCitasAnexos } from '../../../utils/myUtils/getCitasAnexos';
export default function Anexos({ setShow, tokenData }: any) {
  const { CitaID } = tokenData[0];
  const [listaAnexos, setListaAnexos] = useState<any[]>([]);

  useEffect(() => {
    getCitasAnexos(CitaID)
      .then(data => {
        setListaAnexos(data);
      })
      .catch(err => {});
  }, [CitaID]);

  const handleVolver = () => {
    setShow({ mainmenu: true });
  };

  return (
    <div className="options animate__animated animate__fadeIn">
      <div className="titulo_seccion">Anexos</div>
      <hr />
      <button className="main_button back" onClick={handleVolver}>
        Volver
      </button>
      <div className="content">
        <div className="content animate__animated animate__fadeIn">
          {listaAnexos?.length > 0 ? (
            listaAnexos?.map(anexo => (
              <button key={anexo.Nombre} className="main_button">
                <Link target="_blank" style={{ color: 'white' }} href={anexo.DocumentoURL}>
                  {' '}
                  {anexo.Nombre}{' '}
                </Link>
              </button>
            ))
          ) : (
            <p>No hay anexos</p>
          )}
        </div>
      </div>
    </div>
  );
}
