import React, { useContext } from 'react'   
import { TextField } from '@material-ui/core'
import { FormContext } from '../../../../hooks/useForm/FormContext'

export const Personales = ({setShow}: any) => {

    const { formValues ,handleInputChange } = useContext(FormContext);

    const { ant_patologicos, ant_quirurgicos, ant_alergicos} = formValues;
 

    const handleVolver = () => {
        setShow({ant_main:true})
    } 

    return (  
            <div className="options animate__animated animate__fadeIn">
                <div className="titulo_seccion">
                    Ant. Personales
                </div>
                <hr />
                <button className="main_button back" onClick={handleVolver}>
                    Volver
                </button>
                <div className="content" >  
                    <div className="divInput"> 
                        <TextField 
                            id="outlined-basic" 
                            label="Patologicas" 
                            variant="outlined"  
                            type="text" 
                            multiline 
                            fullWidth 
                            rows="5" 
                            color="secondary"
                            onChange={handleInputChange}
                            name="ant_patologicos"
                            value={ant_patologicos}
                        />
                    </div>
                    <div className="divInput"> 
                        <TextField 
                            id="outlined-basic" 
                            label="Quirurgicas" 
                            variant="outlined"  
                            type="text" 
                            multiline 
                            fullWidth 
                            rows="5" 
                            color="secondary"
                            onChange={handleInputChange}
                            name="ant_quirurgicos"
                            value={ant_quirurgicos}
                        />
                    </div>
                    <div className="divInput"> 
                        <TextField 
                            id="outlined-basic" 
                            label="Alergicas" 
                            variant="outlined" 
                            type="text" 
                            multiline 
                            fullWidth 
                            rows="5" 
                            color="secondary"
                            onChange={handleInputChange}
                            name="ant_alergicos"
                            value={ant_alergicos}
                            
                        />
                    </div>
                </div>
            </div>
             
    )
}
