 

export const getIMC = (value:any) => { 
        if(value < 18.5) { 
            return "Peso insuficiente";
        } else if (value >= 18.5 && value < 24.9) {
            return "Normopeso";
        } else if (value >= 25 && value < 26.9) {
            return "Sobrepeso grado I";
        } else if (value >= 27 && value < 29.9) {
            return "Sobrepeso grado II (preobesidad)"
        } else if (value >= 30 && value < 34.9) {
            return "Obesidad de tipo I";
        } else if (value >= 35 && value < 39.9){
            return "Obesidad de tipo II"
        }else if (value >= 40 && value < 49.9) {
            return "Obesidad de tipo III (mórbida)";
        }else if (value >= 50) {
            return "Obesidad de tipo IV (extrema)";
        }else {
            return "-";
        }  
}
