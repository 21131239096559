import React from 'react';
import Video from 'twilio-video';
import { Container, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: { 
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
    textAlign:'center'
  },
  tr: {
    display:'flex',
    justifyContent:'space-between',
    padding: '12px 0px',
    alignItems: 'center'
  }, 
  browsersList: {
    display:'flex', 
    justifyContent:'space-around',
    alignItems:'center',
    flex:'1'
  },
  icon: {
    width:'35px',
  },
  textTitle: {
    fontSize:'0.8rem',
    width:'35px',
    fontWeight:'bold',
    textAlign:'center'
  },
  iconSO: {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  }
});

export default function({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h5" className={classes.heading}>
                Navegadores compatibles
              </Typography>
               <div> 
                  <div className={classes.tr}>
                    <div className={classes.browsersList}> 
                      <div className={classes.textTitle}><div>S.O</div></div>
                      <div className={classes.textTitle}><div>Chrome</div></div>
                      <div className={classes.textTitle}><div>Firefox</div></div>
                      <div className={classes.textTitle}><div>Safari</div></div>
                      <div className={classes.textTitle}><div>Edge</div></div>
                   </div>
                  </div>
                  <div className={classes.tr}>
                    
                    <div className={classes.browsersList}> 
                      <div className={classes.iconSO}><img className={classes.icon} src="/img/png/windows.png" alt="so-img"/><div className={classes.textTitle} ><div>Win</div></div> </div>
                      <div><img className={classes.icon} src="/img/png/chrome.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/firefox.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/edge.png" alt="so-img"/></div>
                   </div>
                  </div>
                  <div className={classes.tr}>
                    <div className={classes.browsersList}> 
                      <div><img className={classes.icon} src="/img/png/ordenador-portatil-apple.png" alt="so-img"/><div className={classes.textTitle}><div>Mac</div></div></div>
                      <div><img className={classes.icon} src="/img/png/chrome.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/firefox.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/safari.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                   </div>
                  </div>
                  <div className={classes.tr}>
                    <div className={classes.browsersList}> 
                      <div><img className={classes.icon} src="/img/png/logotipo-de-mac-os.png" alt="so-img"/><div className={classes.textTitle}><div>iOS</div></div></div>
                      <div><img className={classes.icon}  src="/img/png/eliminar.png" alt="so-img"/></div>
                      <div><img className={classes.icon}  src="/img/png/eliminar.png" alt="so-img"/></div>
                      <div><img className={classes.icon}  src="/img/png/safari.png" alt="so-img"/></div>
                      <div><img className={classes.icon}  src="/img/png/eliminar.png" alt="so-img"/></div>
                   </div>
                  </div>
                  <div className={classes.tr}>
                    <div className={classes.browsersList}> 
                      <div><img className={classes.icon} src="/img/png/linux.png" alt="so-img"/><div className={classes.textTitle}><div>Linux</div></div></div>
                      <div><img className={classes.icon} src="/img/png/chrome.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/firefox.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                   </div>
                  </div>
                  <div className={classes.tr}>
                    <div className={classes.browsersList}> 
                      <div><img className={classes.icon} src="/img/png/android.png" alt="so-img"/><div className={classes.textTitle}><div>Android</div></div></div>
                      <div><img className={classes.icon} src="/img/png/chrome.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/firefox.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                      <div><img className={classes.icon} src="/img/png/eliminar.png" alt="so-img"/></div>
                   </div>
                  </div>
                 
                   
               </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
