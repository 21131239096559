import React, { useEffect, useState }  from 'react';
import { CardCitaMedicaInfo } from '../CardCitaMedicaInfo/CardCitaMedicaInfo'; 
import { getHistoriaClinica } from '../../../utils/myUtils/ExamenClinico';
import { styled } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const CardInfo = styled('div')(({ theme }) => ({
    width:'100%',
    padding: "1em",
    border: "0px none",
    borderRadius: "0.5em",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    cursor: 'pointer',
    marginTop: '1em',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 1em',
      minWidth: '15em'
    },
  })); 

export const HistorialCitas = ({setShow,tokenData, container} : any) => {

    const [historialCitas, setHistorialCitas] = useState<any[]>([]);
    const [citaVista, setCitaVista] = useState<any>('lista');
    const [citaActiva, setCitaActiva] = useState<any>({});

    const { PacienteDNI } = tokenData[0];  

     

    useEffect(() => {
        getHistoriaClinica(PacienteDNI).then(data => {
            setHistorialCitas(data);
        }).catch(err => { 
        })
    }, [PacienteDNI])

    useEffect(() => {
        if(container && citaVista){ 
            container.current.scrollTo(0,0);
        }
    }, [citaVista,container])
    
    
    const handleChangeVista = () => {
        setCitaVista("lista");
        setCitaActiva({});
    }
     
    const handleVolver = () => { 
        setShow({mainmenu:true});
        setCitaVista("lista");
    }

    return (
 
      
        <div className="options animate__animated animate__fadeIn"> 

            <div className="titulo_seccion">
                Historial de Citas
            </div>
            <hr />
            <button className="main_button back" onClick={handleVolver}>
                Volver
            </button>  

            <div className="content animate__animated animate__fadeIn">

                {citaVista === "lista" &&
                
                    <div className="content animate__animated animate__fadeIn">
                        {
                            historialCitas?.length > 0 &&
                            historialCitas.map(info =>  
                                <CardCitaMedicaInfo key={info.serie+info.correlativo} info={info} setCitaVista={setCitaVista}
                                setCitaActiva={setCitaActiva} />
                            )
                        }
                    </div>
                    
                } 

                {citaVista === "unica" && citaActiva?.especialidad.length > 0 &&
                    <div className="content animate__animated animate__fadeIn">
                        <CardInfo>  
                            <div className="dropIcon" onClick={() => handleChangeVista()} > <VisibilityOffIcon /> </div> 
                            <div><b>Especialidad: </b>{citaActiva.especialidad}</div>
                            <div><b>Paciente: </b>{citaActiva.paciente}</div>
                            <div><b>Medico: </b>{citaActiva.medico}</div>
                            <div><b>Fecha Atencion: </b>{citaActiva.fecha_atencion}</div>
                        </CardInfo>

                        <CardInfo>  
                            <div><b>Tiempo Enf: </b>{citaActiva.tiempo_enf}</div>
                            <div><b>Inicio Enf: </b>{citaActiva.inicio_enf}</div>
                            <div><b>Curso Enf: </b>{citaActiva.curso_enf}</div>
                            <div><b>Relato Enf: </b>{citaActiva.relato_enf}</div>
                        </CardInfo>

                        <CardInfo>  
                            <div><b>Talla: </b>{citaActiva.talla}</div>
                            <div><b>Peso: </b>{citaActiva.peso}</div>
                            <div><b>Presion Art: </b>{citaActiva.presion_art}</div>
                            <div><b>Frecuencia Cardiaca: </b>{citaActiva.frecuencia_car}</div>
                            <div><b>Frecuencia Respiratoria: </b>{citaActiva.frecuencia_res}</div>
                        </CardInfo>

                        <CardInfo>  
                            <div><b>Examen clinico general: </b>{citaActiva.examen_c_g}</div> 
                        </CardInfo>

                        <CardInfo>  
                            <div><b>Examen clinico especifico: </b>{citaActiva.examen_c_e}</div> 
                        </CardInfo>

                        <CardInfo>  
                            <div><b>Tratamiento: </b>{citaActiva.tratamiento}</div> 
                        </CardInfo>

                        {citaActiva.diagnosticos.length > 0 &&
                            citaActiva.diagnosticos.map((diagnostico:any) => (
                                <CardInfo>  
                                    <div><b>Diagnostico: </b>{diagnostico.diagnostico}</div> 
                                    <div><b>Tipo: </b>{diagnostico.tipo}</div> 
                                </CardInfo>
                            ))
                        }

                        {citaActiva.receta.length > 0 &&
                            citaActiva.receta.map((receta:any) => (
                                <CardInfo>  
                                    <div><b>Medicamento: </b>{receta.medicamento}</div> 
                                    <div><b>Cantidad: </b>{receta.cantidad}</div> 
                                    <div><b>Frecuencia: </b>{receta.frecuencia}</div> 
                                    <div><b>Duracion: </b>{receta.duracion}</div> 
                                </CardInfo>
                            ))
                        }

                        {citaActiva.examenes_auxiliares.length > 0 &&
                            citaActiva.examenes_auxiliares.map((examen_aux:any) => (
                                <CardInfo>  
                                    <div><b>Especialidad: </b>{examen_aux.especialidad}</div> 
                                    <div><b>Examen: </b>{examen_aux.examen}</div>  
                                </CardInfo>
                            ))
                        }

                    </div>
                }
                
            </div>
        </div>
            
      
        
    )
}
