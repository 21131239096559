
export const actualizarRegistroCita = async (citaRegistroID : any, audiourl: any) => {
    try {
        let json = {citaRegistroID,audiourl};
        const data = await fetch(`${process.env.REACT_APP_API_URL}/token_cita/registro/actualizar`, {
            method:'POST',
            body: JSON.stringify(json), // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/json'
            }
        });
        const JsonData = await data.json(); 
        return JsonData.respuesta;
    } catch (error) {
        return error;
    }
}
