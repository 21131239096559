import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video'; 
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import './videotrack.css'; 

const Video = styled('video')({
  width: '100%',
  maxHeight: '100%',
  objectFit: 'contain' ,
  minHeight:'55vh!important'
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  showInfo? : any
}

export default function VideoTrack({ track, isLocal, priority,showInfo }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};
 
  return <Video ref={ref} style={style} className={`${showInfo ? 'videoHeigth' : ''}`}  />;
}
