import React, { useContext } from 'react' 
import { styled } from '@material-ui/core';  
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FormContext } from '../../../hooks/useForm/FormContext';
import Swal from 'sweetalert2';

const CardInfo = styled('div')(({ theme }) => ({
    width:'100%',
    padding: "1em",
    border: "0px none",
    borderRadius: "0.5em",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px', 
    marginTop: '1em',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 1em',
      minWidth: '15em'
    },
  })); 

export const CardExamenAuxInfo = ({examen_aux}:any) => {

    const { examenesAuxDetalle, setExamenesAuxDetalle } = useContext(FormContext);

    const handleDelete = (examen_aux:any) => { 
      Swal.fire({
        title: 'Eliminar',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar',
        cancelButtonText:'Cancelar'
      }).then( async (result) => {
        if (result.isConfirmed) { 
          let data =  examenesAuxDetalle.filter((e:any) =>  e.id !== examen_aux.id);
          setExamenesAuxDetalle( data );
        }
      });
    }
   

    return (
        <CardInfo className="animate__animated animate__fadeIn" >
            <div className="dropIcon"  onClick={() => handleDelete(examen_aux)}  > <HighlightOffIcon /> </div>   
            <div><b>Especialidad: </b>{examen_aux.especialidad_nombre}</div>
            <div><b>Precio especialidad: </b>S/.{examen_aux.especialidad_precio}</div>
            <div><b>Examen: </b>{examen_aux.examen_nombre}</div> 
            <div><b>Precio examen: </b>{examen_aux.examen_precio}</div> 
        </CardInfo>  
    )
}
