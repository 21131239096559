import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

import { FormContext } from './hooks/useForm/FormContext';
import { useForm } from './hooks/useForm/useForm';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();


  const [formValues, handleInputChange, reset] = useForm(JSON.parse(localStorage.getItem('formValues')!) || {});
  const [diagnosticosDetalle, setDiagnosticosDetalle] = useState<any[]>(JSON.parse(localStorage.getItem('diagnosticosDetalle')!) || []);
  const [medicamentosDetalle, setMedicamentosDetalle] = useState<any[]>(JSON.parse(localStorage.getItem('medicamentosDetalle')!) || []);
  const [examenesAuxDetalle, setExamenesAuxDetalle] = useState<any[]>(JSON.parse(localStorage.getItem('examenesAuxDetalle')!) || []);
  const [onFocusInput, setOnFocusInput] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);

  const refrescarInputs = () => {
    setRefreshButton(!refreshButton);
  }
  

  useEffect(() => {
    localStorage.setItem('formValues', JSON.stringify(formValues));
}, [formValues])

  useEffect(() => { 
    localStorage.setItem('diagnosticosDetalle', JSON.stringify(diagnosticosDetalle));
  }, [diagnosticosDetalle])

  useEffect(() => { 
    localStorage.setItem('medicamentosDetalle', JSON.stringify(medicamentosDetalle));
  }, [medicamentosDetalle])

  useEffect(() => { 
    localStorage.setItem('examenesAuxDetalle', JSON.stringify(examenesAuxDetalle));
  }, [examenesAuxDetalle])


  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
          <FormContext.Provider value={{
                formValues,
                handleInputChange,
                onFocusInput,
                setOnFocusInput,
                diagnosticosDetalle,
                setDiagnosticosDetalle,
                medicamentosDetalle,
                setMedicamentosDetalle,
                examenesAuxDetalle,
                setExamenesAuxDetalle,
                reset,
                refrescarInputs,
                refreshButton
              }}> 
                <App />
            </FormContext.Provider>
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
