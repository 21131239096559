import React from 'react' 

export const Antecedentes = ({setShow, formData} : any) => {
 

    const handleShowAntecedentes = ({target}:any) => { 
        setShow({[target.name]:true});
    }

    const handleVolver = () => {
        setShow({mainmenu:true});
    }
 
    const { formValues , diagnosticosDetalle } = formData;
    const {ant_patologicos ,ant_quirurgicos ,ant_alergicos, ant_familiares, ant_epidemiologicos, ant_peso,
     ant_talla,ant_presion_art, ant_frecuencia_card, ant_frecuencia_resp, ant_temperatura,ant_examenClinicoGeneral, ant_examenClinicoEspecifico , ant_otros} = formValues;
 
      
    return ( 
        <div className="options animate__animated animate__fadeIn">
             <div className="titulo_seccion">
               Antecedentes
             </div>
             <hr />
             <button className="main_button back" onClick={handleVolver}>
                Volver
            </button>
            {/* <button className={`main_button ${ant_patologicos?.length > 0 && ant_quirurgicos?.length > 0 && ant_alergicos?.length > 0 && 'complete'}`} 
                    name="ant_personales" onClick={handleShowAntecedentes}>
                 Personales
            </button>
            <button className={`main_button ${ant_familiares?.length > 0  && 'complete'}`}
                     name="ant_familiares" onClick={handleShowAntecedentes}>
                 Familiares
            </button>
            <button className={`main_button ${ant_epidemiologicos?.length > 0  && 'complete'}`}
                    name="ant_epidemiologicos" onClick={handleShowAntecedentes}>
                 Epidemiológicos
            </button>
            <button className={`main_button ${ant_peso?.length > 0  && ant_talla?.length > 0  
                            && ant_examenClinicoGeneral?.length > 0 && ant_examenClinicoEspecifico?.length > 0 &&
                            ant_presion_art?.length > 0 && ant_frecuencia_card?.length > 0 && ant_frecuencia_resp?.length > 0
                            && ant_temperatura?.length > 0 && 'complete'}`}
                    name="ant_examenclinico" onClick={handleShowAntecedentes}>
                 Exámen Clínico
            </button>
 
            <button className={`main_button ${ant_otros?.length > 0  && 'complete'}`}
                    name="ant_otros" onClick={handleShowAntecedentes}>
                 Otros
            </button> */}

            <button className={`main_button ${diagnosticosDetalle?.length > 0 && 'complete'}`}
                    name="ant_impresion_diagnostica" onClick={handleShowAntecedentes}>
                 Impresion diagnostica
            </button>
        </div>
    )
}
