import React, { ChangeEvent, FormEvent, useState, useEffect, useRef, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Box, ButtonBase } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { TiempoRestante as Tiempo } from './../MenuBar/TiempoRestante/TiempoRestante';
import SaveIcon from '@material-ui/icons/Save';
import CachedIcon from '@material-ui/icons/Cached';
import Swal from 'sweetalert2';
import { actualizarRegistroCita } from '../../utils/myUtils/actualizarRegistroCita';
import { FormContext } from '../../hooks/useForm/FormContext';
import { guardarAudio, insertarAtencionMedica, insertarAtencionMedicaLocal } from '../../utils/myUtils/ExamenClinico';
import './menubar.css';
import { actualizarEstadoCita } from '../../utils/myUtils/actualizarEstadoCita';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    divEntrar: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
    especialidadNombre: {
      display: 'flex',
      textAlign: 'center',
      color: '#02B0ED',
      width: '100%',
      justifyContent: 'center',
    },
    informacionUsuarioDoctor: {
      display: 'flex',
      flexDirection: 'column',
      padding: '12px 0px 0px 12px',
      color: '#808080',
    },
  })
);

export default function MenuBar({ tokenData, params, dataUser, setDataUser, showInfo, insertarRegistro }: any) {
  const classes = useStyles();
  const { URLRoomName } = useParams<any>();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [appBarOculto, setAppBarOculto] = useState(false);
  const [citaRuta, setCitaRuta] = useState('');
  const [citaRutaState, setCitaRutaState] = useState(true);
  const [buttonRegistrarEnabled, setButtonRegistrarEnabled] = useState(false);

  const {
    formValues,
    diagnosticosDetalle,
    medicamentosDetalle,
    setDiagnosticosDetalle,
    setMedicamentosDetalle,
    examenesAuxDetalle,
    setExamenesAuxDetalle,
    reset,
    refrescarInputs
  } = useContext(FormContext);

  

  let {
    tiempo_enfermo,
    inicio,
    curso,
    relato,
    ant_patologicos,
    ant_quirurgicos,
    ant_alergicos,
    ant_familiares,
    ant_epidemiologicos,
    ant_otros,
    ant_peso,
    ant_talla,
    ant_imc,
    ant_presion_art,
    ant_frecuencia_card,
    ant_frecuencia_resp,
    ant_temperatura,
    ant_examenClinicoGeneral,
    ant_examenClinicoEspecifico,
    receta_tratamiento,
    recetaTexto
  } = formValues;

  useEffect(() => {
    if(diagnosticosDetalle?.length > 0 && tiempo_enfermo?.length > 0 
      && inicio?.length > 0 && curso?.length > 0 && relato?.length > 0 
      && recetaTexto?.length > 0 && receta_tratamiento?.length > 0 ) {
      setButtonRegistrarEnabled(true);
    } else {
      setButtonRegistrarEnabled(false);
    }
  }, [formValues,diagnosticosDetalle])

  const { type } = params;
  const { nombreLocal, nombreSegundoParticipante, startVideo, CitaRegistroID } = dataUser;
  const {
    CitaID,
    EspecialidadNombre,
    Serie,
    Correlativo,
    FechaAtencion,
    MedicoIDSC,
    MedicoUsuarioID,
    TokenID,
    PacienteUsuarioID,
  } = tokenData[0];
  const stop = useRef<any>();

  const [isRecording, setisRecording] = useState(false);

  useEffect(() => {
    if (nombreLocal) {
      setName(nombreLocal);
    }
  }, [nombreLocal]);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const vaciarLocalStorage = async () => {
    try {
      reset({});
      setDiagnosticosDetalle([]);
      setMedicamentosDetalle([]);
      setExamenesAuxDetalle([]);
      refrescarInputs();
      Swal.fire('Aviso', 'Se limpiaron los datos', 'warning');
    } catch (error) {
      return error;
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (type === '1') {
        await insertarRegistro(TokenID, MedicoUsuarioID);
        setDataUser((data: any) => ({ ...data, startVideo: true }));
      } else {
        await insertarRegistro(TokenID, PacienteUsuarioID);
      }
      // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
      if (!window.location.origin.includes('twil.io')) {
        window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
      }
      getToken(name, roomName).then(token => connect(token));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      type === '1' &&
      startVideo === true &&
      roomState === 'connected' &&
      stop.current !== null &&
      isRecording === false
    ) {
      let device = navigator.mediaDevices.getUserMedia({ audio: true });
      let items: any[] = [];
      let recorder: MediaRecorder;
      device.then(stream => {
        recorder = new MediaRecorder(stream);

        if (roomState === 'connected') {
          console.log('EMPEZO A GRABAR');

          recorder.start(1000);
          setisRecording(true);
        }

        if (stop.current) {
          stop.current.onclick = function() {
            setisRecording(false);
            recorder.stop();
          };
        }

        recorder.onstop = async e => {
          try {
            console.log('SE PARO LA GRABACION');

            let blob = new Blob(items, { type: 'audio/webm' });
            var file = new File([blob], `CitaID-${CitaID}-B${Serie}-${Correlativo}`);

            const formData = new FormData();
            formData.append('audio', file);

            const dataAudio = await guardarAudio(formData);
            setCitaRuta(dataAudio.RUTA);
            setCitaRutaState(true);

            console.log('SE GRABO');
          } catch (error) {}
        };

        recorder.ondataavailable = e => {
          items.push(e.data);
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, startVideo, roomState, stop.current, isRecording]);

  useEffect(() => {
    if (citaRuta.length > 0 && CitaRegistroID > 0 && citaRutaState === true) {
      setCitaRutaState(false);
      console.log('ENTRO A ACTUALIZACION DE URL');
      actualizarRegistroCita(CitaRegistroID, citaRuta);
    }
  }, [citaRuta, CitaRegistroID, citaRutaState]);

  const registrarFormulario = () => {
    console.log('ENTRO AL ONCLICK');
    Swal.fire({
      title: 'Registro del formulario',
      text: 'Solo se puede registrar el formulario una unica vez',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Registrar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          console.log('ENTRO AL GUARDAR');

          const diagnosticosArray = diagnosticosDetalle.map((detalle: any) => {
            return {
              cie: detalle.cie10.toString(),
              tipo: detalle.tipoID.toString(),
            };
          });

          console.log(diagnosticosArray);

          const medicamentosArray = medicamentosDetalle.map((medicamento: any) => {
            return {
              id_medicamento: medicamento.medicamento_id.toString(),
              cantidad: medicamento.cantidad.toString(),
              id_dosis: medicamento.id_dosis.toString(),
              id_via_adm: medicamento.id_via_administracion.toString(),
              frecuencia: medicamento.medicamento_frecuencia.toString(),
              duracion: medicamento.medicamento_duracion.toString(),
            };
          });
          console.log(medicamentosArray);

          const examenesAuxArray = examenesAuxDetalle.map((examenAux: any) => {
            return {
              id_producto: examenAux.id_producto.toString(),
            };
          });

          console.log(examenesAuxArray);

          const objetoAtencionMedica = {
            serie: 'B'+Serie,
            correlativo: Correlativo || '',
            fecha_atencion: FechaAtencion || '',
            id_medico: MedicoIDSC || '',
            tiempo_enf: tiempo_enfermo || '',
            inicio: inicio || '',
            curso: curso || '',
            relato: relato || '',
            ant_patologicos: ant_patologicos || '',
            ant_qx: ant_quirurgicos || '',
            alergias: ant_alergicos || '',
            ant_fam: ant_familiares || '',
            ant_epid: ant_epidemiologicos || '',
            ant_otros: ant_otros || '',
            peso: ant_peso || '',
            talla: ant_talla || '',
            imc: ant_imc || '',
            presion_art: ant_presion_art || '',
            frecuencia_car: ant_frecuencia_card || '',
            frecuencia_r: ant_frecuencia_resp || '',
            temperatura: ant_temperatura || '',
            examen_clinico_general: ant_examenClinicoGeneral || '',
            examen_clinico_especifico: ant_examenClinicoEspecifico || '',
            tratamiento: receta_tratamiento || '',
            detalle_cie: diagnosticosArray || [],
            detalle_receta: medicamentosArray || [],
            detalle_examenes: examenesAuxArray || [],
          };

          console.log(objetoAtencionMedica);

          insertarAtencionMedica(objetoAtencionMedica)
            .then(async dataAtencionMedica => {
              if (dataAtencionMedica?.error?.length > 0) {
                Swal.fire('Aviso', dataAtencionMedica.error, 'warning');
                setDiagnosticosDetalle([]);
                setMedicamentosDetalle([]);
                setExamenesAuxDetalle([]);
                reset({});
                return;
              } else if (dataAtencionMedica?.error?.length === 0) {
                if (recetaTexto) await insertarAtencionMedicaLocal(CitaID, recetaTexto);
                Swal.fire('Aviso', 'Se registro el formulario correctamente', 'success');
                setDiagnosticosDetalle([]);
                setMedicamentosDetalle([]);
                setExamenesAuxDetalle([]);
                reset({});
                actualizarEstadoCita(CitaID, 3).then(data => {});
              }
            })
            .catch(err => {
              Swal.fire('Aviso', 'Ocurrio un error, por favor recargue la pagina', 'error');
            });
        } catch (err) {
          Swal.fire('Aviso', 'Ocurrio un error, por favor recargue la pagina', 'error');
        }
      }
    });
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      if (showInfo) {
        setAppBarOculto(true);
      } else {
        setAppBarOculto(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, showInfo]);

  //mis cambios
  //Agregue el svg Box para movil y desktop

  return (
    <AppBar
      className={`${classes.container} animate__animated animate__fadeInDown`}
      position="static"
      style={{ display: `${appBarOculto ? 'none' : ''}` }}
    >
      <Box display={{ xs: 'flex', sm: 'none', md: 'none' }} justifyContent="center" mt={2}>
        <img src={'/img/logo.svg'} style={{ width: '50%' }} alt="logo"></img>
      </Box>
      <Toolbar className={classes.toolbar}>
        <Box p={2} display={{ xs: 'none', sm: 'flex', md: 'flex' }}>
          <img src={'/img/logo.svg'} style={{ width: '15em' }} alt="logo"></img>
        </Box>
        {roomState === 'disconnected' ? (
          <div className={classes.divEntrar}>
            <form className={classes.form} onSubmit={handleSubmit}>
              {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
                <TextField
                  id="menu-name"
                  label="Name"
                  className={classes.textField}
                  value={name}
                  onChange={handleNameChange}
                  margin="dense"
                  disabled
                  style={{ display: 'none' }}
                />
              ) : (
                <Typography className={classes.displayName} variant="body1">
                  {user.displayName}
                </Typography>
              )}
              <TextField
                id="menu-room"
                label="Room"
                className={classes.textField}
                value={roomName}
                onChange={handleRoomNameChange}
                margin="dense"
                disabled
                style={{ display: 'none' }}
              />
              <Button
                className={classes.joinButton}
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
                // style={{display:'none'}}
              >
                Unirse
              </Button>
              {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
            </form>
            <div className={classes.informacionUsuarioDoctor}>
              <p style={{ margin: 0 }}>
                Hola <b>{nombreLocal}</b>.
              </p>
              <p style={{ margin: 0 }}>
                En unos momentos entrará {type === '1' ? '' : 'el médico'} <b>{nombreSegundoParticipante}</b>.
              </p>
            </div>
          </div>
        ) : (
          <div className={classes.especialidadNombre}>
            <p style={{ fontSize: '1.5em', display: 'flex', flexDirection: 'column' }}>
              {EspecialidadNombre}
              <Tiempo tokenData={tokenData} setDataUser={setDataUser} />
            </p>
          </div>
        )}
        <div className={classes.rightButtonContainer}>
          <div className="buttonsMedico">
            <Button
              ref={stop}
              color="secondary"
              variant="contained"
              style={{ display: type === '1' ? '' : 'none', borderRadius: '5em' }}
              onClick={registrarFormulario}
              disabled={buttonRegistrarEnabled ? false : true }
            >
              <SaveIcon />
            </Button>
            <Button
              color="secondary"
              variant="contained"
              style={{ display: type === '1' ? '' : 'none', borderRadius: '5em', backgroundColor: 'red' }}
              onClick={vaciarLocalStorage}
            >
              <CachedIcon />
            </Button>
          </div>
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu params={params} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
