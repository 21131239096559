import React, { useContext, useState, useEffect } from 'react';
import { FormContext } from '../../../../hooks/useForm/FormContext';
import {
  TextField,
  MenuItem,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { getDiagnostico, getDiagnosticoTipo } from '../../../../utils/myUtils/ExamenClinico';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CreateIcon from '@material-ui/icons/Create';
import { CardDiagnosticoInfo } from '../../CardDiagnosticoInfo/CardDiagnosticoInfo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: 'none',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  })
);

export const ImpresionDiagnostica = ({ setShow, container }: any) => {
  const { setDiagnosticosDetalle, diagnosticosDetalle } = useContext(FormContext);

  const [values, setValues] = useState<any>({});
  const { ant_diagnostico_tipo } = values;

  const [diagnosticos, setDiagnosticos] = useState<any[]>([]);
  const [diagnosticosTipo, setDiagnosticosTipo] = useState<any[]>([]);
  const [vista, setVista] = React.useState('registro');

  const [impresionDiagnostica, setImpresionDiagnostica] = React.useState<any>({});
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    getDiagnosticoTipo()
      .then(data => {
        setDiagnosticosTipo(data);
      })
      .catch(err => {});
  }, []);

  //AUTOCOMPLETE
  useEffect(() => {
    if (inputValue?.length === 2) {
      getDiagnostico(inputValue)
        .then(data => {
          setDiagnosticos(data);
        })
        .catch(err => {});
    }
  }, [inputValue]);

  const handleVolver = () => {
    setShow({ ant_main: true });
  };

  const handleAgregarDiagnostico = () => {
    if (impresionDiagnostica?.cie10 !== undefined && ant_diagnostico_tipo?.TipoID !== undefined) {
      setDiagnosticosDetalle((detalle: any) => [
        ...detalle,
        {
          id: uuidv4(),
          cie10: impresionDiagnostica.cie10,
          descripcion: impresionDiagnostica.descripcion,
          tipo_diagnostico: ant_diagnostico_tipo.Descripcion,
          tipoID: ant_diagnostico_tipo.TipoID,
        },
      ]);
      setInputValue('');
      setImpresionDiagnostica({});
      setValues((values: any) => ({ ...values, ant_diagnostico_tipo: '' }));
      setVista('diagnostico');
      Swal.fire('Mensaje', 'Se agrego correctamente el diagnostico', 'success');
    }
  };

  const handleSelectChange = async ({ target }: any) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const classes = useStyles();

  return (
    <div className="options animate__animated animate__fadeIn">
      <div className="titulo_seccion">Ant. Impresion Diagnostica</div>
      <hr />
      <button className="main_button back" onClick={handleVolver}>
        Volver
      </button>
      <div className="content">
        <BottomNavigation
          value={vista}
          onChange={(event, newValue) => {
            setVista(newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction label="Ver Diagnosticos" value="diagnostico" icon={<ReceiptIcon />} />
          <BottomNavigationAction label="Registrar" value="registro" icon={<CreateIcon />} />
        </BottomNavigation>

        {vista === 'registro' && (
          <div className="content animate__animated animate__fadeIn">
            <div className="divInputAutocomplete">
              <Autocomplete
                defaultValue={[]}
                value={impresionDiagnostica}
                onChange={(event: any, newValue: {}) => {
                  setImpresionDiagnostica(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={diagnosticos || []}
                getOptionLabel={option => option.descripcion}
                fullWidth
                renderInput={params => (
                  <TextField {...params} label="Impresion diagnostica" variant="outlined" color="secondary" />
                )}
              />
            </div>

            {/* <div className="divInput"> 
                    <TextField  
                        label="Diagnostico" 
                        aria-describedby="Diagnostico"  
                        color="secondary"
                        onChange={handleInputChange}
                        name="input_diagnostico"
                        fullWidth
                        value={input_diagnostico}
                        autoComplete="off"
                    />
                </div>  */}
            {/* <div className="divInputSelect">  
                    <TextField 
                        select
                        label="Impresion Diagnostica" 
                        helperText="Selecciona un diagnóstico"
                        color="secondary"
                        fullWidth
                        onChange={handleSelectChange}
                        name="ant_impresion_diagnostica"
                        value={ant_impresion_diagnostica}   
                        >  
                        <MenuItem key={0} value="">
                            Ninguno
                        </MenuItem>
                        {  
                            diagnosticos?.length > 0 &&
                                diagnosticos.map((diagnostico) => (
                                    <MenuItem key={diagnostico.cie10} value={diagnostico} >
                                        {diagnostico.descripcion}
                                    </MenuItem> 
                                ))   
                        }
                        
                    </TextField>
                </div>  */}
            <div className="divInputSelect">
              <TextField
                select
                label="Diagnostico Tipo"
                helperText="Selecciona el tipo de diagnóstico"
                color="secondary"
                fullWidth
                onChange={handleSelectChange}
                name="ant_diagnostico_tipo"
                value={ant_diagnostico_tipo}
              >
                <MenuItem key={0} value="">
                  Ninguno
                </MenuItem>
                {diagnosticosTipo?.length > 0 &&
                  diagnosticosTipo.map(diagnosticoTipo => (
                    <MenuItem key={diagnosticoTipo.DiagnosticoTipoID} value={diagnosticoTipo}>
                      {diagnosticoTipo.Descripcion}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="divInput">
              <Button color="secondary" variant="contained" fullWidth onClick={handleAgregarDiagnostico}>
                Agregar
              </Button>
            </div>
          </div>
        )}

        {vista === 'diagnostico' &&
          diagnosticosDetalle?.length > 0 &&
          diagnosticosDetalle.map((diagnostico: any) => (
            <CardDiagnosticoInfo key={diagnostico.id} diagnostico={diagnostico} />
          ))}
      </div>
    </div>
  );
};
